.main {
  padding-top: 80px;
}
.accountInfo {
  color: #fff;
  padding: 0 7%;
}
.likedVideos {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-bottom: 100px;
}

.poster {
  width: 32%;
  margin-right: 1%;
  margin-bottom: 1%;
  cursor: pointer;
}

.spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.likedTitle {
  font-size: 30px;
  margin-bottom: 20px;
  margin-top: 50px;
}

.btns {
  text-align: center;
}
