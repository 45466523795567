.inputs {
  color: black;
}

.main {
  margin-top: 100px;
  margin-bottom: 100px;
}

.form {
  color: #000;
  padding: 0 20%;
  margin-top: 50px;
}

.gobacklink {
  color: #fff;
  text-align: center;
  padding: 0 20%;
  font-size: 20px;
  text-decoration: underline;
}

.response {
  font-size: 20px;
  color: #fff;
}

.episode {
  padding: 20px;
  margin-top: 10px;
  margin-bottom: 10px;
  color: #fff;
  background-color: rgb(123, 43, 21);
}
