body {
  margin: 0;
  font-family: "Montserrat", sans-serif;
  background-color: #303030;
}

.tvMessage {
  color: #fff;
  display: flex;
  justify-content: center; /* Horizontal centering */
  flex-direction: column;
  align-items: center; /* Vertical centering */
  height: 100vh; /* Set height to full viewport height for vertical centering */
}

/* Optional: Adjust styles for title and text */
.tvMessage__title {
  text-align: center;
}

.tvMessage__text {
  text-align: center;
}

/* .video-js .vjs-control-bar {
  background-color: rgb(255, 166, 0) !important;
} */

.video-js .vjs-big-play-button {
  border-radius: 50% !important;
  width: 80px !important;
  height: 80px !important;
  margin-left: -40px !important;
  line-height: 80px !important;
  font-size: 50px !important;
  border: none !important;
  background-color: #d71961 !important;
  z-index: 100 !important;
}

.video-js .vjs-big-play-button::before {
  content: "";
  background: #d71961;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: block;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  animation: pulse-border 1.5s ease-out infinite;
  z-index: 0;
}

.carousel-next {
  background: linear-gradient(to left, #272727, rgba(255, 255, 255, 0));
  border-radius: 0%;
  height: 97%;
  width: 50px;
  font-size: 26px;
  color: #fff;
}

.carousel-next i {
  opacity: 0.7;
}

.carousel-next:hover i {
  color: #fff;
  opacity: 1;
}

.carousel-prev {
  background: linear-gradient(to right, #272727, rgba(255, 255, 255, 0));
  border-radius: 0%;
  height: 97%;
  width: 50px;
  font-size: 26px;
  color: #fff;
}

.carousel-prev i {
  opacity: 0.7;
}

.carousel-prev:hover i {
  color: #fff;
  opacity: 1;
}

@keyframes pulse-border {
  0% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    opacity: 1;
  }

  100% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
    opacity: 0;
  }
}

@media (max-width: 600px) {
  .video-js .vjs-big-play-button {
    width: 40px !important;
    height: 40px !important;
    margin-left: -20px !important;
    line-height: 40px !important;
    font-size: 25px !important;
  }

  .video-js .vjs-big-play-button::before {
    width: 40px;
    height: 40px;
  }
}
