.main {
  padding: 0 7%;
}

.form {
  max-width: 600px;
  margin: 0 auto;
  margin-top: 150px;
}

.error {
  color: rgb(156, 0, 0);
  font-weight: 700;
}

.modalImg {
  background-image: url("../../assets/modal-img.jpg");
  height: 140px;
  border-radius: 20px;
  margin: 40px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-around;
  transition: box-shadow 0.3s ease;
}

.modalImg:hover {
  box-shadow: 0px 0px 20px #d71961;
}

.playBtn {
  width: 53px;
  height: 53px;
  background-color: #d71961;
  border-radius: 50%;
  position: relative;
}

.triangle {
  width: 0;
  height: 0;
  border-top: 15px solid transparent;
  border-bottom: 15px solid transparent;
  border-left: 22px solid #fff;
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(65%, -50%);
}

.modalBody {
  border: 2px solid #8d8d8d;
  margin: 40px;
  border-radius: 20px;
}
.registerBtn {
  cursor: pointer;
}

.registerBtn:hover {
  text-decoration: underline;
}

@media (max-width: 960px) {
  .modalImg {
    padding: 0 5%;
  }
  .modalImg p {
    width: 70%;
  }
}
