.title {
  font-size: 30px;
}

.episodeTitle {
  font-size: 30px;
}

.videoBlock {
  width: 48%;
}

.info {
  font-size: 16px;
  margin-top: 10px;
}

.main {
  display: flex;
  justify-content: space-between;
  padding: 180px 10% 100px;
}

.navigation {
  margin-bottom: 30px;
}

.navigationItem {
  border-bottom: 2px solid #8d8d8d;
  padding: 15px;
  cursor: pointer;
}
.navigationItemActive {
  color: #fff;
  font-weight: 700;
  border-bottom: 2px solid #fff;
  padding: 15px;
  cursor: pointer;
}

.desc {
  color: lightgrey;
  width: 48%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.spinnerDiv {
  display: flex;
  justify-content: center;
}

.spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.likeBtn {
  font-size: 20px;
}

.likeIcon {
  cursor: pointer;
  margin-right: 5px;
  color: crimson;
  margin-top: 20px;
}

.episode {
  background-color: #646464;
  border-radius: 19px;
  padding: 20px;
  color: #fff;
  cursor: pointer;
  font-size: 20px;
  width: 95%;
  margin-bottom: 15px;
  transition: background-color 0.3s ease-in-out;
}

.episode:hover {
  background-color: #848484;
}

.episodesList {
  list-style: none;
}

.episodeContainer {
  overflow-y: visible;
  overflow-x: hidden;
  max-height: 400px;
}

.episodeContainer::-webkit-scrollbar {
  height: 10px;
  width: 10px;
}

.episodeContainer::-webkit-scrollbar-track {
  background: #303030;
}

.episodeContainer::-webkit-scrollbar-thumb {
  background: #888;
  border: -100px solid #888;
  background-clip: content-box;
  cursor: pointer;
  border-radius: 20px;
}

.episodeContainer::-webkit-scrollbar-thumb:hover {
  background: #555;
}

@media (max-width: 960px) {
  .main {
    flex-direction: column;
    align-items: flex-start;
    padding: 140px 10% 50px;
  }
  .videoBlock {
    width: 100%;
  }
  .desc {
    width: 100%;
    margin-top: 50px;
  }
  .likeBtn {
    margin-top: 40px;
  }
}
