.main {
  background-color: #303030;
  height: 100vh;
}

.video-js .vjs-big-play-button {
  border-radius: 50%;
}

.spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.player {
  padding-top: 80px;
}

@media (max-width: 500px) {
  .player {
    padding-top: 100px;
    background-color: #303030;
  }
}
