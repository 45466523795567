.subscribeLink {
  background-color: green;
  margin-right: 15px;
  border-radius: 5px;
  text-align: center;
  padding: 7px 15px;
  color: #fff;
  text-decoration: none;
  font-weight: 700;
}

.subscribeLink:hover {
  text-decoration: none;
  background-color: darkgreen;
}

.subscribeLink a:hover {
  text-decoration: none;
}
