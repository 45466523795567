.container {
  position: relative;
}
/* .skipBtn {
  left: 0;
  bottom: 0;
} */
.planInfo {
  position: absolute;
  left: 15%;
  bottom: 35px;
  color: #fff;
  width: 70%;
}

.planInfoBox {
  background-color: #646464;
  opacity: 0.9;
  width: 100%;
  border-radius: 20px;
  padding: 10px 20px;
}

.planInfoContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.planInfoCounter {
  align-self: flex-start;
  margin-left: 20px;
}

.playerInfo {
  cursor: pointer;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 998;
  color: #fff;
  padding: 0 0 50px 10%;
  width: 100%;
  background: linear-gradient(rgba(255, 255, 255, 0), #303030);
}

.playerInfo h1 {
  font-size: 50px;
}

.playerInfo p {
  font-size: 25px;
  font-weight: 300;
  margin-top: 15px;
}

.planInfoBox:hover {
  opacity: 1;
}

@media (max-width: 1150px) {
  .playerInfo {
    cursor: pointer;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 998;
    color: #fff;
    padding: 0 0 20px 10%;
    width: 100%;
  }
  .playerInfo h1 {
    font-size: 30px;
  }

  .playerInfo p {
    font-size: 16px;
  }
}
@media (max-width: 850px) {
  .planInfo {
    display: none;
  }
}
@media (max-width: 600px) {
  .playerInfo h1 {
    font-size: 16px;
  }

  .playerInfo p {
    font-size: 12px;
    margin-top: 10px;
  }
}
