.gobacklink {
  color: #fff;
  text-align: center;
  font-size: 20px;
  text-decoration: underline;
  display: block;
  margin-bottom: 20px;
  font-weight: 600;
}

.main {
  padding: 0 15%;
  margin-top: 50px;
}

.userTable {
  width: 100%;
  border-collapse: collapse;
  margin-top: 50px;
  background-color: #2a2a2a;
  border-radius: 8px;
  overflow: hidden;
}

.userTable thead {
  background-color: #444;
}

.userTable th,
.userTable td {
  padding: 15px;
  text-align: left;
  border-bottom: 1px solid #555;
}

.userTable th {
  font-size: 16px;
  color: #fff;
  font-weight: 700;
  text-transform: uppercase;
}

.userTable td {
  color: #ddd;
  font-size: 15px;
}

.userTable tbody tr:hover {
  background-color: #3b3b3b;
}

.userTable tbody tr:nth-child(even) {
  background-color: #323232;
}

.pagination {
  color: #fff;
  font-weight: 700;
  margin-top: 20px;
  display: flex;
  justify-content: center;
  margin-bottom: 50px;
}

.pagination button {
  background-color: #444;
  color: #fff;
  border: none;
  padding: 10px 20px;
  margin: 0 10px;
  font-size: 16px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.pagination button:hover {
  background-color: #555;
}

.pagination button:disabled {
  background-color: #666;
  cursor: not-allowed;
}

.pagination span {
  line-height: 40px;
  color: #fff;
  font-size: 16px;
}
