.main {
  background-color: #303030;
  height: 80px;
  position: fixed;
  top: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 7%;
  z-index: 999;
}

.menu {
  display: flex;
  list-style: none;
  color: #fff;
}

.menu li {
  margin-left: 40px;
  cursor: pointer;
}

.auth {
  font-size: 18px;
}

.greetings {
  color: #fff;
  margin-right: 15px;
}

.logoWrapper {
  cursor: pointer;
}

@media (max-width: 960px) {
  .title {
    display: none;
  }
  .menu li {
    font-size: 12px;
    margin-left: 10px;
  }
}
