.mainContainer {
  color: #fff;
  margin-top: 20px;
}

/* EPG Container */
.epgContainer {
  width: 100%;
  background-color: transparent;
  color: #ddd;
  overflow-x: auto;
  white-space: nowrap;
}

/* EPG Header */
.epgHeader {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0.5rem;
  position: relative;
}

.arrowSvg .arrowPath {
  transition: stroke 0.2s ease;
}

.arrowSvg:hover .arrowPath {
  stroke: #686868;
}

.epgHeaderDate div:first-child::first-letter {
  text-transform: uppercase;
}

.arrow {
  cursor: pointer;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  margin: 0 0.6rem;
}

.epgHeaderDate {
  cursor: pointer;
  text-align: center;
  line-height: 1.3rem;
  width: 9rem;
}

.epgHeaderDate.selected {
  color: #d71961;
  cursor: default;
}

.epgHeaderDate.selected:hover {
  color: #d71961;
}

.epgHeaderDate:hover {
  color: #c5c4c4;
}

.epgHeaderTitle {
  font-size: 1.25rem;
  font-weight: 400;
  left: 10%;
  top: 0;
  position: absolute;
  cursor: pointer;
}

.epgHeaderTitle h3 {
  margin: 0rem;
}

.epgHeaderTitle:hover {
  color: #c5c4c4;
}

.headerContainer {
  display: flex;
  justify-content: center;
  position: relative;
}

@media screen and (max-width: 1099px) {
  .epgHeaderTitle {
    left: 2%;
  }
}

@media screen and (max-width: 960px) {
  .epgHeaderTitle {
    position: relative;
    margin-bottom: 0.5rem;
  }

  .headerContainer {
    flex-direction: column;
    align-items: center;
  }
}

@media screen and (max-width: 600px) {
  .epgHeaderDate {
    width: 6rem;
  }

  .epgHeaderTitle h3 {
    margin: 0.8rem;
  }

  .arrow {
    margin: 0 0.5rem;
  }
}

/* Timeline */
.epgTimeline {
  display: flex;
  font-size: 0.6875rem;
  margin-bottom: 0.5rem;
  width: max-content;
  position: relative;
}

.timelineHour {
  width: 39rem;
  position: relative;
}

.timelineHour:first-child {
  width: 38.15rem;
}

.timelineHour:nth-child(2) {
  width: 39rem;
}

.timelineHour:nth-child(47) {
  width: 39rem;
}

/* Programs */
.program {
  display: inline-block;
  background-color: #686868;
  white-space: nowrap;
  cursor: pointer;
  border: 2px solid #686868;
  padding: 0.3rem 0rem;
  margin-right: 0.5rem;
  box-sizing: border-box;
  overflow: hidden;
  text-overflow: ellipsis;
  border-radius: 0.8rem;
  height: 5.625rem;
}

.program:hover {
  border: 2px solid #d9d9d9;
}

.programContainer {
  margin-bottom: 1rem;
  height: 97.859px;
}

.program:last-child {
  margin-right: 0;
}

.programInfo {
  align-items: center;
  padding-left: 0.8rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.programTitle {
  font-size: 1rem;
  font-weight: 500;
  margin-bottom: 0rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.programTime {
  font-size: 1rem;
  margin-top: 0rem;
}

/* Currently Airing */
.currentlyAiring {
  background-color: #d9d9d9;
  border: 2px solid #d9d9d9;
  color: #303030;
}

.currentlyAiring:hover {
  border: 2px solid #686868;
}

.currentTimeLine {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 2px;
  height: 135px;
  background-color: #d71961;
  z-index: 900;
  overflow: hidden;
}

/* Loader */
.loaderContainer {
  position: absolute;
  right: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 6.054rem;
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
}

.loader .circle {
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  background-color: #686868;
  animation: pulsate 1s infinite ease-in-out;
}

.loader .circle:nth-child(2) {
  background-color: #ffffff;
  animation-delay: 0.2s;
}

.loader .circle:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes pulsate {
  0%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.5);
  }
}

/* Scrollbar */
.epgContainer::-webkit-scrollbar {
  height: 0.75rem;
}

.epgContainer::-webkit-scrollbar-track {
  background: #303030;
}

.epgContainer::-webkit-scrollbar-thumb {
  background: #888;
  border: -100px solid #888;
  background-clip: content-box;
  cursor: pointer;
  border-radius: 20px;
  width: 100px;
}

.epgContainer::-webkit-scrollbar-thumb:hover {
  background: #555;
}
