.main {
  padding: 80px 10% 0 10%;
}
.videos {
  width: 100%;
}
.spinner {
  display: flex;
  justify-content: center;
  margin-top: 100px;
  margin-bottom: 100px;
}
.desc {
  color: #fff;
}
.title {
  font-size: 50px;
}

.searchedVideos {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-bottom: 100px;
  margin-top: 40px;
}

.poster {
  width: 32%;
  margin-right: 1%;
  margin-bottom: 1%;
  cursor: pointer;
}

@media (max-width: 960px) {
  .main {
    padding: 100px 5% 0 5%;
  }
  .title {
    font-size: 27px;
    margin-bottom: 10px;
  }
}
