.gobacklink {
  color: #fff;
  text-align: center;
  font-size: 20px;
  text-decoration: underline;
}

.main {
  padding: 0 20%;
  margin-top: 100px;
}