.main {
  padding: 0 10%;
  height: 100%;
  color: #fff;
}

.offers {
  display: flex;
  height: 500px;
  justify-content: space-between;
}

.title {
  font-size: 30px;
  margin-top: 100px;
  margin-bottom: 50px;
}

.linkToMain {
  display: block;
  width: 32%;
  height: auto;
}

.freeBox {
  background-image: url("../../assets/jesus.jpg");
  background-position: right;
  border-radius: 50px;
  width: 100%;
  height: 100%;
  cursor: pointer;
  position: relative;
  transition: box-shadow 0.3s ease;
}

.freeBoxInfo {
  font-weight: 700;
  text-align: center;
  padding-top: 50px;
  font-size: 20px;
}

.playBtn {
  width: 100px;
  height: 100px;
  background-color: #d71961;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
}

.triangle {
  width: 0;
  height: 0;
  border-top: 28px solid transparent;
  border-bottom: 28px solid transparent;
  border-left: 44px solid #fff;
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(65%, -50%);
}

.freeBox:hover {
  box-shadow: 0px 0px 40px #d71961;
}

.payedBox {
  width: 32%;
  height: auto;
  border-radius: 50px;
  background-color: #646464;
  transition: box-shadow 0.3s ease;
  cursor: pointer;
  padding: 50px 5%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.payedBox:hover {
  box-shadow: 0px 0px 40px #d71961;
}

.priceOne {
  font-size: 50px;
  text-align: center;
}

.mobileBtn {
  display: none;
}

.priceOne::after {
  content: "na miesiąc";
  font-size: 14px;
  display: block;
  margin-top: -10px;
}

.priceTwo {
  font-size: 50px;
  text-align: center;
}

.priceTwo::after {
  content: "na rok";
  font-size: 14px;
  display: block;
  margin-top: -10px;
}

.payedBoxInfo {
  font-weight: 700;
  text-align: center;
  font-size: 20px;
  margin-top: 20px;
}

.payedBoxPerks {
  list-style: none;
  width: 100%;
  margin-top: 40px;
  margin-bottom: 20px;
}

.payedBoxPerks li {
  margin-bottom: 10px;
  font-size: 20px;
}

.spinner {
  display: flex;
  justify-content: center;
  margin-top: 100px;
  margin-bottom: 100px;
}

@media (max-width: 1600px) {
  .payedBox {
    padding: 20px 2% 50px;
  }
}

@media (max-width: 1200px) {
  .offers {
    flex-direction: column;
    height: auto;
  }
  .linkToMain {
    width: 100%;
    height: 300px;
    margin-bottom: 50px;
  }
  .freeBox {
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
  .payedBox {
    width: 100%;
    min-height: 500px;
    padding: 50px 10%;
    margin-bottom: 50px;
  }
}

@media (max-width: 700px) {
  .mobileBtn {
    display: block;
  }
  .payedBox {
    padding: 20px 5%;
  }
  .payedBoxPerks li {
    font-size: 16px;
  }
  .payedBoxDesc {
    font-size: 14px;
  }
}
