.main {
  background-color: #303030;
  color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 60px 11% 60px 11%;
}

.regulations {
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-top: 40px;
}

.socials {
  margin-top: 18px;
}

.regulations a {
  color: lightgray;
  margin-right: 20px;
  font-weight: 700;
  text-decoration: none;
}
.regulations span {
  color: lightgray;
  font-weight: 700;
  margin-top: 40px;
}

.icons {
  display: flex;
}

.icon {
  width: 40px;
  height: 40px;
  background-color: rgba(41, 41, 41, 0.76);
  border-radius: 50%;
  cursor: pointer;
  margin-right: 10px;
  position: relative;
  transition: all 0.3s ease;
}

.icon a i {
  color: #fff;
  font-size: 18px;
  display: flex;
  justify-content: center;
  transition: color 0.3s ease;
}

.icon:hover a i {
  color: #d71961;
}

.icon:hover {
  box-shadow: 0px 0px 10px #d71961;
}

.icon a {
  text-decoration: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.findText {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 25px;
}

@media (max-width: 960px) {
  .main {
    flex-direction: column-reverse;
  }

  .regulations {
    padding: 0 5%;
    margin-bottom: 20px;
    margin-top: 100px;
  }
  .socials {
    margin-top: 50px;
    width: 100%;
  }
  .findText {
    text-align: center;
  }
  .icons {
    justify-content: space-around;
  }
  .icon {
    margin-right: 0;
  }
}
